body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #dce7ed !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none!important;
}

img {
    max-width: 100%;
}

.header_site {
    padding: 30px 0 !important;
}

.homebanner {
    height: 600px;
    background-image: url("images/banner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.topbnr_text h1 {
    font-size: 45px;
    color: #06446b;
}

.topbnr_text {
    padding: 25px 0;
    width: 90%;
    position: relative;
    top: 100px;
}

.topbnr_text h2 {
    font-size: 80px;
    font-weight: 700;
    color: #06446b;
}

.topbnr_text h2 span {
    font-weight: 400;
}

.topbnr_text p {
    font-size: 18px;
    color: #06446b;
}

.appimgs {
    margin-top: 30px;
}

.hdng_row {
    margin-bottom: 30px;
}

.hdng_row p {
    font-size: 18px;
    color: #03253b;
}

.flt_text p {
    margin-bottom: 0;
}

a.bookbtn {
    color: #fff;
    background: #06446b;
    padding: 6px 15px;
    border-radius: 30px;
    transition: all 0.5s;
}
.search_cb {
    transition: all 0.5s;
}
.search_cb:hover, a.bookbtn:hover {
    background: #dfe9ef;
    color: #06446b;
    transition: all 0.5s;
}
.appimgs img {
    width: 140px;
    height: 45px;
    margin-right: 20px;
}

.home_tabs_wrapper {
    background: #dce7ed;
    padding: 20px;
    position: relative;
    z-index: 99;
    border-radius: 20px;
    box-shadow: -7px -12px 20px 5px rgb(255 255 255 / 80%);
    width: 84%;
    margin: 0 auto;
    top: 80px;
}
.bnnr_submitbtn input.search_cb {box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);position: absolute;left: 0;}

.bnnr_submitbtn {position: relative;}

.home_tabs_wrapper li.nav-item button {
    color: #06446b !important;
    font-weight: 500;
    width: 90px;
    padding: 10px 0;
}

.home_tabs_wrapper li.nav-item button.active {
    background: #dce7ed;
    box-shadow: 1px 5px 10px 6px rgb(92 154 188 / 47%);
    border-radius: 30px;
    margin: 0;
    position: relative;
    border-color: transparent;
}

ul.searchtabs.nav.nav-tabs {
    margin-bottom: 20px !important;
    display: flex;
    justify-content: space-between;
}

.home_tabs_wrapper:after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    top: 0;
    box-shadow: 1px 5px 10px 6px rgb(92 154 188 / 47%);
    z-index: -1;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent;
}

.form-element {
    position: relative;
}

.form-element svg {
    position: absolute;
    top: 25px;
    left: 26px;
    font-size: 20px;
    color: #06446b;
}

.DatePicker ~ svg {
    z-index: 9999;
}

.home_tabs_wrapper .form-control,
.home_tabs_wrapper .form-select {
    margin: 10px 0;
    height: 50px;
    border-radius: 25px;
    /* box-shadow: 24px 24px 48px #5c9abc, -24px -24px 48px #ffffff; */
    position: relative;
    background-color: #e4eef4;
    color: #06446b;
    padding-left: 40px;
    font-weight: 500;
}

.DatePicker input {
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
}
.home_tabs_wrapper input::placeholder {
    color: #06446b;
    font-weight: 500;
}

input.br-none {
    padding-left: 20px !important;
}
span.add_city {display: block;margin: 15px 10px;text-align: right;font-weight: 500;color: #06446b;}

.home_tabs_wrapper .form-control:before,
.home_tabs_wrapper .form-select:before {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    top: 0;
    box-shadow: 24px 24px 48px #5c9abc, -24px -24px 48px #ffffff;
}

.home_tabs_wrapper .container {
    padding: 0;
}

.search_cb {
    background: #06446b;
    color: #fff;
    width: 100%;
    border-radius: 30px;
    height: 50px;
    border: 0;
    margin: 15px 0;
}

.slider-fleet-items img {
    height: 160px;
    object-fit: contain;
}

.slider-fleet-items .onewaybox {
    background: #fff;
    position: relative;
    box-shadow: inset 7px 7px 5px 0px rgb(245 249 251), inset -6px -8px 5px 0px rgb(92 154 188 / 50%);
    padding: 25px 20px;
    border-radius: 25px;
    text-align: center;
}

.flt_text {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 0 10px;
    border-top: 3px dashed #8c8c8b;
    margin-top: 20px;
    align-items: center;
    margin-bottom: 10px;
}

.flt_text p {
    font-weight: bold;
    font-size: 18px;
}

.j-align-center {
    align-items: center;
    justify-content: center;
}

.aboutsec img {
    height: 580px;
    object-fit: contain;
}

.aboutsec .right_text p {
    width: 95%;
    text-align: justify;
}

.btn_know {
    background: #dce7ed;
    /* box-shadow: 1px 5px 10px 6px rgb(92 154 188 / 47%); */
    box-shadow: 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
    border-radius: 30px;
    margin: 0;
    position: relative;
    font-weight: 600;
    border-color: transparent;
    color: #06446b !important;
    padding: 12px 25px !important;
    transition: all 0.5s;
}

a.btn_know:hover {
    background: #06446b;
    color: #fff !important;
    transition: all 0.5s;
}

.c_btn {
    display: inline-block;
    margin-top: 30px;
    position: relative;
}

.btn_know:before {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    top: 0;
    box-shadow: -14px -14px 22.5px 2.5px rgba(255, 255, 255, 1);
    /* box-shadow: -4px -12px 20px 5px rgb(255 255 255 / 80%); */
}

.add_download .right_img {
    height: 500px;
}

.add_download .right_img img {
    height: 500px;
}

.valuebox {
    text-align: center;
    box-shadow: -7px -12px 20px 5px rgb(255 255 255 / 80%), 1px 5px 10px 6px rgb(92 154 188 / 47%);
    position: relative;
    border-radius: 25px;
    padding: 55px 20px;
}

.valuebox img {
    width: 65px;
    height: 65px;
    object-fit: contain;
}

.vl_txt {
    padding: 25px 10px 0;
}


/* .valuebox:after {
  content: '';
  position: absolute;
  display: block;
  right: 0;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  top: 0;
  box-shadow: 1px 5px 10px 6px rgb(92 154 188 / 47%);
  z-index: -1;
} */

.sect_padding {
    padding: 30px 0;
}

.header_btn {
    margin-left: 20px;
}

ul {
    padding: 0 !important;
    list-style: none;
    margin: 0 !important;
}

.header_btn ul {
    display: flex;
    align-items: center;
}

.header_btn a.nav-link.btn_know {
    margin-right: 15px;
    width: 130px;
    text-align: center;
}

.header_btn a.nav-link.btn_know.bbtn {
    background: #06446b;
    color: #fff !important;
}

.header_site .navbar-nav a.nav-link {
    color: #06446b !important;
    font-weight: 500;
    padding: 0 15px;
}

.vdoslider-items iframe {
    width: 97%;
    height: 260px;
    border-radius: 30px;
    margin: 10px 0;
}

.vdoslider-items .item {
    box-shadow: inset -6px -8px 5px 0px rgb(255 255 255);
    border-radius: 30px;
    position: relative;
    z-index: 999;
    text-align: center;
    height: 280px;
}

.vdoslider-items .item:after {
    box-shadow: inset 7px 7px 5px 0px rgb(92 154 188 / 50%);
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    border-radius: 25px;
}

.pop_links a {
    color: #06446b;
    font-weight: 400;
}

.row.pop_links {
    padding: 40px 0;
}

.footer-section .container {
    padding: 50px 15px;
    border-top: 1px solid #c9c7c7;
    border-bottom: 1px solid #c9c7c7;
}

.footer-section h5 {
    color: #06446b;
    margin-bottom: 15px;
}

.foot_box li {
    padding-bottom: 6px;
}

.foot_box a {
    color: #06446b;
}

.footer {
    padding: 20px 0;
}

.footer-bottom p.text-center {
    padding: 20px 0 0 0;
    margin: 0;
}

h2.site_hdng {
    font-size: 45px;
    font-weight: bold;
    color: #06446b;
    margin-bottom: 20px;
}

.top_filterrow .form-control,
.top_filterrow .form-select {
    margin: 10px 0;
    height: 50px;
    border-radius: 25px;
    /* box-shadow: 24px 24px 48px #5c9abc, -24px -24px 48px #ffffff; */
    box-shadow: -14px -14px 22.5px 2.5px rgba(255, 255, 255, 1), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
    position: relative;
    background-color: #e4eef4;
    color: #06446b;
    padding-left: 40px;
    font-weight: 500;
}

.top_filterrow .form-element {
    float: left;
}

.top_filterrow .col-sm-2.form-element {
    width: 13%;
    margin-right: 10px;
}

.top_filterrow .col-sm-2.form-element select,
.top_filterrow .col-sm-1.form-element select {
    padding-left: 10px;
}

.top_filterrow .col-sm-1.form-element {
    width: 10%;
    margin-right: 10px;
}

.top_filterrow .col-sm-1.form-element input {
    padding-left: 35px;
}

.top_filterrow .form-element svg {
    left: 12px;
}

.top_filterrow .search_cb {
    padding-left: 0 !important;
}

.top_filterrow form {
    display: flex;
    align-items: center;
}

@media(max-width:1366px) {
    .top_filterrow .form-element input,
    .top_filterrow .form-element select {
        font-size: 14px;
    }
}

.cstm_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 27px;
    z-index: 99;
    height: 30px;
    width: 30px;
}


/* Create a custom radio button */

.cstm_radio .checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: inset 2px 2px 4px #5c9abc, inset -2px -2px 4px #ffffff;
    ;
    height: 28px;
    width: 28px;
    border-radius: 8px;
}


/* When the radio button is checked, add a blue background */

.cstm_radio input:checked~.checkmark {
    background-color: transparent;
    border-color: #06446b;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.cstm_radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.cstm_radio input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.cstm_radio .checkmark:after {
    top: 7px;
    left: 7px;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    background: #06446b;
}

.cstm_radio {
    position: relative;
}

.cstm_radio label {
    padding-left: 40px;
}

li.filter-df {
    display: flex;
    justify-content: space-between;
}

.side_filter {
    box-shadow: 24px 24px 48px #5c9abc, -24px -24px 48px #ffffff;
    padding: 25px;
    border-radius: 25px;
}

.row.top_filterrow {
    background: #bfd4e0;
    border-radius: 40px;
    padding: 7px 15px;
    /* box-shadow: 24px 24px 48px #5c9abc, -24px -24px 48px #ffffff; */
    box-shadow: -14px -14px 22.5px 2.5px rgba(255, 255, 255, 1), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
}

.cabs_det_slider .item img {
    width: 140px !important;
    height: 80px;
    margin: 0 auto;
    object-fit: contain;
}

.abtcab .cb_img {
    width: 37%;
}

.row.abtcab {
    display: flex;
}

.cb_txt {
    width: 63%;
    padding: 10px 20px;
}

.cabbox {
    border: 2px solid #efeeee;
    box-shadow: 5px 4px 5px 0px rgb(94 90 90);
    margin-bottom: 20px;
    border-radius: 25px;
}

.cabsall {
    background: #fff;
    border-radius: 25px;
    margin: 10px 10px;
    padding: 10px;
}

ul.cb_feat li {
    border-right: 2px solid;
    padding: 0 12px;
    color: #003366;
    font-weight: 500;
    line-height: 18px;
}

ul.cb_feat li:first-child {
    padding-left: 0;
}

ul.cb_feat li:last-child {
    border: 0;
}

.cabs_det_slider .owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #565353 !important;
    color: #fff !important;
    width: 12px;
    height: 30px;
}

.cabs_det_slider {
    position: relative;
}

.cabs_det_slider .owl-nav button.owl-next {
    right: -15px;
}

.cabs_det_slider .owl-nav button.owl-prev {
    left: -10px;
}

.bookby p {
    border: 1px solid;
    padding: 2px 20px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
}

.cb_icons img {
    padding: 0 10px;
}

.cb_inform {
    margin-top: 12px;
    flex-wrap: wrap;
}

.cb_inform p {
    padding: 0 30px;
    margin: 0;
    border-right: 2px solid;
    line-height: 18px;
}

.cb_inform p:last-child {
    border: 0;
}

.cb_inform p:first-child {
    padding-left: 10px;
}

.cb_txt p {
    margin-bottom: 5px;
    margin-top: 8px;
    font-size: 15px;
}

.cabsall .flt_text {
    padding-top: 14px;
}

.filtrtype ul.fi-list {
    padding: 15px 0 !important;
}

.filtrtype {
    margin-top: 15px;
}

.details-fleet-items img {
    height: 400px;
    object-fit: cover;
    border-radius: 25px;
}

.details-fleet-items .item {
    padding: 10px;
    box-shadow: -7px -12px 20px 5px rgb(255 255 255 / 80%), 1px 5px 10px 6px rgb(92 154 188 / 47%);
    border-radius: 25px;
}

.details-fleet-items .owl-stage-outer {
    height: 470px;
}

.details-fleet-items .owl-stage {
    top: 33px;
}

.cab_dt_box {
    box-shadow: inset -6px -8px 5px 0px rgb(255 255 255), inset 7px 7px 5px 0px rgb(92 154 188 / 50%);
    border-radius: 25px;
    padding: 35px 30px;
}

.cab_dt_box .cb_txt {
    padding-left: 0;
    font-weight: 500;
    padding-top: 0;
    color: #003366 !important;
}

p.rate_avg {
    background: #05a484;
    color: #fff;
    height: 30px;
    width: 60px;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    align-items: center;
    float: right;
}

p.rate_avg svg {
    font-size: 18px;
}

.cb_prc {
    text-align: right;
}

.cab_dt_box .cb_prc span {
    display: block;
}

.d-flex.safty {
    align-items: center;
    justify-content: flex-start;
    border-top: 3px dashed #8c8c8b;
    padding-top: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
}

.safty p {
    margin: 0;
    padding-left: 20px;
}

ul.blue_list {
    list-style: disc;
    padding-left: 25px !important;
    overflow: auto;
}

ul.blue_list li {
    width: 50%;
    float: left;
    padding: 5px 0;
    font-size: 20px;
    color: #003366;
    font-weight: 500;
}

ul.grey_list li {
    display: flex;
    align-items: center;
    padding: 5px 0;
}

ul.grey_list li p {
    margin: 0;
    font-weight: 500;
    padding-left: 10px;
}

.grey_list img {
    height: 22px;
    width: 22px;
    object-fit: contain;
}

ul.grey_list {
    margin-top: 10px !important;
    display: block;
}

p {
    color: #575757;
}

h4.sub_hdng {
    color: #003366;
    font-weight: bold;
}

.cab_dt_box .cb_txt p {
    color: #003366;
}

h3.site_sub_hdng {
    font-size: 35px;
    font-weight: bold;
    color: #003366;
    margin-bottom: 25px;
    margin-top: 15px;
}

.list_disc li {
    display: list-item !important;
}

.list_disc {
    list-style: circle;
    flex-direction: column;
    padding-left: 15px !important;
}

.list_disc li p {
    padding-left: 0 !important;
}

.amenitieslist li {
    padding: 15px 0 !important;
}

.amenitieslist li {
    padding: 15px 0 !important;
}

.ratebox {
    color: #fff;
    background: #003366;
    width: 150px;
    height: 150px;
    display: flex;
    border-radius: 25px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ratebox p {
    color: #fff;
    text-align: center;
    margin: 0;
    font-size: 12px;
}

.farebox {
    box-shadow: -7px -12px 20px 5px rgb(255 255 255 / 80%), 1px 5px 10px 6px rgb(92 154 188 / 47%);
    padding: 20px;
    border-radius: 25px;
    margin: 35px 0;
    align-items: center;
}

.frtxt {
    width: 200px;
    padding: 15px 20px;
}

.frtxt span {
    color: #003366;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
}

.frtxt h3 {
    color: #003366;
    font-size: 35px;
    font-weight: bold;
}

.frbtn a.bookbtn {
    width: 190px;
    display: block;
    text-align: center;
    font-size: 22px;
    height: 60px;
    line-height: 60px;
    padding: 0;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
}

.box_sh_inner {
    box-shadow: inset -6px -8px 5px 0px rgb(255 255 255), inset 7px 7px 5px 0px rgb(92 154 188 / 50%) !important;
}

.col-sm-1.list_locbtn {
    width: 13% !important;
}

.header_btn .btn_know {
    padding: 12px 10px;
}

.header_btn .btn_know svg {
    position: relative;
    left: -10px;
}

.cb_inform p {
    padding: 0 15px;
}

h2.sitepage_hdng {
    font-weight: bold;
    color: #06446b;
    font-size: 40px;
}

.route_info svg {
    font-size: 50px;
}

p.rt_dtl {
    color: #03253b;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.bookicon {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
    border-radius: 50%;
    justify-content: center;
}

.modal-content {
    background: #e4eef4;
    text-align: center;
}

.bookicon {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
    border-radius: 50%;
    justify-content: center;
}

.modal-content {
    background: #e4eef4 !important;
    text-align: center;
    padding: 30px 0;
    border-radius: 25px !important;
}

.bookicon img {
    width: 100px;
    height: 85px;
    text-align: center;
}

.modal h2.sitepage_hdng {
    font-size: 30px;
}

.modal p.rt_dtl {
    font-size: 18px;
}

.mdl_hdng {
    font-size: 27px;
    color: #06446b;
    font-weight: 700;
    margin-bottom: 20px;
}

.bookidbox p {
    width: 45%;
    margin: 0 auto;
    height: 48px;
    line-height: 48px;
    border-radius: 25px;
    font-weight: 500;
    color: #06446b;
}

.bookidbox {
    margin-bottom: 20px;
}

.mdl_text {
    padding: 10px 35px;
}

.mdl_text p {
    color: #354049;
}

.mdlbtn a {
    box-shadow: -7px -12px 20px 5px rgb(255 255 255 / 80%), 1px 5px 10px 6px rgb(92 154 188 / 47%);
    padding: 12px 30px;
}

.trip_left,
.trip_right {
    padding: 0 20px!important;
}

.trip_left {
    border-right: 2px dashed #8c8c8b;
    padding-left: 0!important;
}

.trip_upr_wrap {
    border-bottom: 2px dashed #8c8c8b;
    padding-bottom: 25px;
    margin: 0 20px!important;
}

.offr_row {
    margin-top: 20px!important;
    padding-left: 30px!important;
    margin-bottom: 20px!important;
}

.offr_row img {
    width: 40px;
    margin-right: 20px;
}

.estmt_txt {
    padding-left: 30px;
    margin-bottom: 25px;
}

.finalbox {
    padding-right: 30px;
    text-align: right;
}

.location_tofrom svg {
    color: #003366;
    margin-right: 12px;
    font-size: 35px;
    position: relative;
    top: 5px;
}

.location_tofrom span {
    font-size: 20px;
    color: #003366;
    font-weight: 600;
}

.location_tofrom p {
    font-size: 22px;
    color: #131313;
    font-weight: 500;
}

p.pricetxt {
    font-weight: 600;
    font-size: 22px;
    color: #131313;
}

p.pricetxt span {
    color: #7f7e7e;
    font-size: 18px;
}

.trip_right h5 {
    font-weight: 700;
    color: #003366;
    font-size: 25px;
}

.trip_km p {
    color: #003366;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    top: 5px;
    left: 12px;
}

.abtcab p {
    color: #003366;
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;
}

.offr_row h5 {
    font-size: 22px;
    color: #003366;
}

.estmt_txt h4 {
    font-size: 28px;
    font-weight: 600;
    color: #003366;
}

h3.finalprc {
    font-size: 40px;
    font-weight: bold;
    color: #003366;
}

p.cpn_apply {
    color: #009d76;
    font-weight: 600;
    font-size: 20px;
}

.estmt_txt p {
    font-size: 18px;
    font-weight: 500;
}

.textslide_upr img {
    width: 100px !important;
    border-radius: 15px;
}

.img_name {
    display: flex;
}

.img_name img {
    margin-right: 20px;
}

.top_filterrow label {
    color: #06446b !important;
    font-weight: 500;
    font-size: 18px;
    padding: 10px 0;
}

.packgboxlist {
    /* box-shadow: inset 7px 7px 5px 0px rgb(92 154 188 / 50%), inset -6px -8px 5px 0px rgb(255 255 255); */
    box-shadow: inset -9px -20px 20px 4px rgb(92 154 188 / 50%), inset 18px -16px 20px 12px rgb(255 255 255);
    padding: 10px;
    border-radius: 25px;
    margin-bottom: 25px;
}

.pkgimg img {
    border-radius: 25px;
}

.pkgcont {
    padding: 20px 10px;
    text-align: center;
    background: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.pkgimg {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background: #fff;
}

.pkgcont h4 {
    font-size: 20px;
    color: #06446b;
}

p.cbtype {
    color: #06446b;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 6px;
}

p.cbprice {
    color: #000;
    font-weight: 600;
    font-size: 18px;
}

.hdngrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.faqcontainr .accordion-item {
    margin-bottom: 15px;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
    border-radius: 25px;
}

.faqcontainr .accordion-item h2.accordion-header button {
    background: #dce7ed !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.faqcontainr .accordion-item .accordion-body {
    background: #dce7ed;
    border-radius: 25px;
}

.accordion-button:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.accordion-item {
    background-color: #dce7ed !important;
    border-radius: 25px !important;
    padding: 10px 15px;
}

.blgbox .pkgcont {
    text-align: left;
    background-color: #dce7ed !important;
}

.blgbox.packgboxlist {
    box-shadow: -7px -12px 20px 5px rgb(255 255 255 / 80%), 1px 5px 10px 6px rgb(92 154 188 / 47%);
}

.blgbox .pkgimg {
    background-color: #dce7ed !important;
}

.bnnrtxt {
    position: absolute;
    top: 50%;
    color: #fff;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
}

.bnnrtop {
    position: relative;
}

.bnnrtop img {
    height: 250px;
    width: 100%;
    border-radius: 25px;
    object-fit: cover;
}

.bnnrtxt p {
    color: #fff;
}

.pkgdscsldr img {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.pkgdscsldr .pkgcont {
    background: #dce7ed;
    text-align: left;
}

.whiteshdow {
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
}

section.featr_tour .col-sm-3 {
    width: 100%;
}

.pkginsbox {
    border-radius: 25px;
    padding: 25px;
}

.pkginshdng {
    border-radius: 25px;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;
    align-items: center;
    margin-top: 20px;
}

.pkginshdng h4 {
    margin: 0;
}

.aboutpkg {
    padding: 0 35px;
}

.aboutpkg .innerdtl p {
    padding: 15px 0;
    margin: 0;
    font-size: 16px;
    color: #000;
    border-bottom: 1px solid #938484;
}

.querybox {
    padding: 30px 15px;
    border-radius: 25px;
    text-align: center;
    color: #003366;
}

.querybox p {
    font-size: 18px;
    color: #003366;
    font-weight: 500;
}

.querybox h4 {
    font-size: 26px;
}

.querybox label {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
}

.querybox input {
    border-radius: 25px;
    height: 50px;
    margin-bottom: 15px;
    text-align: center;
}

.querybox a.bookbtn {
    margin-top: 25px;
    display: block;
    height: 45px;
    line-height: 45px;
    padding: 0;
    font-size: 20px;
}

.lightwhiteshdow {
    box-shadow: 24px 24px 48px #5c9abc, -24px -24px 48px #ffffff;
}

.pkgname a {
    color: #06446b;
    font-weight: 500;
}

.pkgname {
    padding: 15px;
    border-radius: 25px;
    margin-bottom: 15px;
}

.col-sm-6.aboutpkg {
    padding: 30px 25px;
}

.pkgshrtdesc {
    padding: 15px 0;
}

.col-sm-10.dealsimgs {
    padding-left: 30px;
}

.col-sm-10.dealsimgs img {
    border-radius: 25px;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
}

.dealshdng {
    border-radius: 25px;
    text-align: center;
    padding: 10px 0;
    background-color: #fff;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
}

.dealshdng h2 {
    color: #fff;
    background-image: linear-gradient(to top right, #c33409, #f46c08);
    font-size: 38px;
    text-transform: uppercase;
    line-height: 25px;
    font-weight: 700;
    padding: 15px 0;
    border-radius: 25px;
}

.dealshdng h2 span {
    font-size: 15px;
    line-height: 28px;
}

.dealshdng a {
    color: #0c354f;
    font-weight: bold;
    text-decoration: underline !important;
    font-size: 18px;
}

.onewaybox h3 {
    color: #0e4c72;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 22px;
}

.onewaybox img {
    border-radius: 25px;
    height: 185px;
    object-fit: cover;
}

.onewaybox ul {
    text-align: left;
}

.onewaybox ul li {
    background: #dce7ed;
    margin: 5px 0;
    border-radius: 25px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 500;
}

.pop_links .col-sm-2 {
    width: 20%;
}

.rght_hdng a {
    color: #366382;
    font-weight: bold;
    text-decoration: underline !important;
    font-size: 20px;
}

.text_testimon_slider .item {
    padding: 15px;
}

.textslide_upr {
    box-shadow: -6px -9px 20px 5px rgb(255 255 255 / 80%), 1px 5px 10px 6px rgb(92 154 188 / 47%);
    border-radius: 25px;
    padding: 15px;
}

span.quotes {
    position: absolute;
    top: 25px;
    transform: rotate(180deg);
    left: 24px;
    color: #b8d3e1;
}

.img_name {
    margin-top: 25px;
}

p.stars {
    color: #fbbb00;
}

.width50 {
    width: 48%;
    float: left;
    margin: 8px 1%;
}

.sbmenu {
    border-radius: 50px;
}

.sbmenu ul li {
    display: inline-block;
}

.sbmenu ul {
    margin: 0 auto;
    text-align: center;
}

.sbmenu ul li a {
    color: #06446b;
    font-weight: 500;
    padding: 15px 20px;
    display: block;
    border-radius: 25px;
    width: 150px;
}

.sbmenu ul li a.active {
    background: #06446b;
    color: #fff;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
}

span.stars svg {
    color: #ffc039;
    font-size: 25px;
    position: relative;
    top: -2px;
    left: 5px;
}

.cabtype {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 25px;
    padding: 25px 15px;
    position: relative;
    margin-bottom: 22px;
}

.bgwhite {
    background-color: #fff !important;
}

.cabtype h5 {
    padding-left: 15px;
}

.cabtype img {
    width: 110px;
}

.cabtype input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 15px;
    right: 25px;
    z-index: 999;
}

.moreimg {
    display: table;
    width: 100%;
}

.pkgcont p {
    font-size: 18px;
    line-height: 30px;
}


/* Create a custom radio button */

.cabtype .checkmark {
    position: absolute;
    top: 10px;
    right: 15px;
    height: 32px;
    width: 32px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */


/* .cabtype:hover input ~ .checkmark {
  background-color: #17d12d;
} */

input:focus~.checkmark {
    box-shadow: 0px 0px 8px #56CCF2;
    border-color: #56CCF2;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.cabtype input:checked~.checkmark:after {
    display: block;
}

.cabtype input:checked~.checkmark {
    background-color: #17d12d;
}


/* Style the indicator (dot/circle) */

.cabtype .checkmark:after {
    left: 10px;
    top: 5px;
    width: 8px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.DatePicker {
    position: initial;
}
.accordion-button:not(.collapsed) {
    color: #003366 !important;
    font-weight: 500;
}


.round_tbl {
    padding: 40px;
}


.round_tbl tbody tr {
    display: table-row;
    height: 80px;
    border-bottom: 1px solid #91a2ab !important;
}
.round_tbl th {padding: 15px;}

.round_tbl tr td {padding: 10px 15px; display: table-cell;}

.round_tbl  tr {display: table-row;}

table    { display: table }
tr       { display: table-row }
thead    { display: table-header-group }
tbody    { display: table-row-group }
tfoot    { display: table-footer-group }
td, th   { display: table-cell }

.deals-items .item {
    margin: 35px 17px;
}

.pkg_topfiltr .col-sm-3.form-element {
    padding: 0 10px;
}

.filterDiv{
    width: 21% !important;
}

.timepkr {position: relative;}
.timepkrTop {top: -11px; }

.timepkr input.rc-time-picker-input { color: #06446b !important;height: 50px;border-radius: 25px;position: relative;top: 11px; background: #e9ecef;box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);  text-align: center; font-size: 1rem; font-weight: 600; }
.timepkr span.rc-time-picker { width:100% }
.timepkr svg {position: absolute;left: 10px;top: 24px;}

a.rc-time-picker-clear {top: 22px;right: 10px;}
.DatePicker input.form-control {
    color: #06446b !important;
}

.top_filterrow input.br-none {
    padding-left: 40px !important;
}

.conttext {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: inset 7px 7px 5px 0px rgb(245 249 251), inset -6px -8px 5px 0px rgb(92 154 188 / 50%);
    padding: 25px 20px;
    border-radius: 25px;
    margin: 30px 0;
}

.contact_dtl {
    flex: 0  0 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_dtl strong {
    display: block;
}

.contact_dtl p {
    margin-bottom: 0;
    padding-left: 10px;
}

.c_formwrppr .form-control {
    margin: 10px 0;
    height: 55px;
    border-radius: 25px;
    box-shadow: inset -6px -8px 5px 0px rgb(255 255 255), inset 7px 7px 5px 0px rgb(92 154 188 / 50%) !important;
    position: relative;
    background-color: #e4eef4;
    color: #06446b;
    padding-left: 40px;
    font-weight: 500;
}

.c_formwrppr .form-control::placeholder {
    padding-left: 10px !important;
}

.c_formwrppr label {
    padding-left: 20px;
}
.c_formwrppr button.btn.btn-primary {
    background: #06446b;
    color: #fff;
    width: 100%;
    border-radius: 30px;
    height: 50px;
    border: 0;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
    margin: 15px auto;
    transition: all 0.5s;
}
.c_formwrppr button.btn.btn-primary:hover{
    background: #dfe9ef;
    color: #06446b;
    
}
.contact_dtl a {
    color: #575757;
}
.foot_box a.nav-link {
    padding-left: 0;
}


@media(max-width:640px) {
    .homebanner {   height: 485px; }
    .header_site  .container-fluid {padding: 0;}

.header_site .navbar-collapse {background: #dce7ed;border-radius: 10px;box-shadow: 1px 5px 10px 6px rgb(92 154 188 / 47%);
    position: absolute;width: 100%;top: 90px;padding: 10px;z-index: 99999;}
    .timepkr input.rc-time-picker-input {top: 10px;margin-bottom: 18px;}
    .header_site .navbar-collapse li.nav-item {
        padding: 5px 0;
    }
.timepkr span.rc-time-picker {display: block;}
    .hdngrow p {
        display: none;
    }
    p.rt_dtl { font-size: 15px;}
    span.stars svg { font-size: 20px;}
    .topbnr_text,
    .header_btn {
        display: none;
    }
    .home_tabs_wrapper {
        width: 100%;
        top: 0;
    }
    .DatePicker {
        width: 100%;
    }
    .DatePicker input {
        text-align: left !important;
        padding-left: 40px !important;
    }
    .hdngrow .rght_hdng {
        flex: 0 0 25%;
        text-align: center;
    }
    .rght_hdng a { font-size: 15px;}
    h3.site_sub_hdng {
        font-size: 21px;
        margin: 0;
    }
    .dealshdng {
        width: 90% !important;
        margin: 0px auto 20px auto;
    }
    h2.site_hdng {
        font-size: 25px;
    }
    h2.site_hdng br {
        display: none;
    }
    .add_download .right_img {
        height: 300px;
        text-align: center;
        margin-top: 20px;
    }
    .add_download .right_img img {
        height: 300px;
    }
    .row.pop_links {
        padding: 0px 0;
    }
    .pop_links .col-sm-2 {
        width: 45%;
        text-align: center;
        margin-bottom: 15px;
    }
    .footer-section .container {
        padding: 30px 15px; 
    }
        .home_tabs_wrapper li.nav-item button {
            width: 75px;
            font-size: 14px;
        }
        .top_filterrow form {
            flex-wrap: wrap;
        }
        
        .top_filterrow form .form-element {
            width: 48%!important;
            float: left;
            margin: 0 1%!important;
        }
        
        .pkg_topfiltr form .form-element {
            width: 100%!important;
        }
        .bnnr_submitbtn input.search_cb  { position: relative; }
        .sect_padding {
            padding: 15px 0;
        }
        h2.sitepage_hdng {
         font-size: 25px;
        }
        ul.cb_feat li { padding: 0 5px; }
        .bookby p {  padding: 2px 6px; }
        .cb_icons img {
            padding: 0 5px;
        }
        .cb_inform p {
            padding: 0 10px;
        }
        .cabs_det_slider .item img {
            width: 120px !important;
            height: 65px;
         }
         .valuebox { padding: 20px; margin-bottom: 15px;}
         .details-fleet-items .owl-stage-outer, .details-fleet-items img { height: 200px;}
         .details-fleet-items .owl-stage {
            top: 0;
        }
        .frbtn a.bookbtn {
            width: 160px;
            margin-left: 15px; 
        }
        .location_tofrom span {  font-size:16px; }
        .trip_left { border-right:0; }
            .location_tofrom p { font-size:16px; }
            p.pricetxt { font-size:15px;}
            .trip_left, .trip_right { padding:0 0px!important; }
            .trip_km p { font-size:18px;}
            .offr_row img { width:30px; } 
            .offr_row h5 { font-size:18px; }
            .estmt_txt h4 {  font-size:22px; }
            .estmt_txt p, p.cpn_apply { font-size:14px; }
            h3.finalprc, .frtxt h3 {  font-size:30px; }
            .round_tbl th {
                padding: 5px;
            }
            .round_tbl {
                padding: 15px;
            }
            .round_tbl .row {
                overflow-x: auto;
            }
            .round_tbl tr td { padding: 5px 15px; }
            .round_tbl a.btn_know {
                    width: 145px;
                    display: block;
             }
             .round_tbl th {
                padding: 5px 20px;
            }     
}


.filterAddMoreBtn{
        margin-top: 20px;
        float: left;
        cursor: pointer;
        border-radius: 50px;
        border: 2px solid rgb(17, 17, 17);
        width: 31px;
        height: 31px;
        padding: 8px;
        line-height: 9px;
        margin-left: -17px;
}

.removeBtnCss{
        margin-top: 20px;
        float: left;
        cursor: pointer;
        border-radius: 50px;
        border: 2px solid rgb(17, 17, 17);
        width: 31px;
        height: 31px;
        padding: 8px;
        line-height: 9px;
        margin-left: -17px;
        background: transparent;
}

#coupanmodal .modal-dialog{ max-width: 1000px;}

.cpnbox.cab_dt_box {
    padding: 20px;
    margin-bottom: 15px;
}

.cpnbox  ul {
    text-align: left;
    list-style: disc;
    padding: 0 20px !important;
    margin-bottom: 20px !important;
}

.red {
    color: #A2191F !important;
}

.pkgtxt_btns {
    flex: 0 0 70%;
    padding-left: 15px;
}

.pkgtxt_price .pkgtxt {
    flex: 0 0 75%;
}

.tourprc {
    flex: 0 0 25%;
    text-align: right;
}
.packgboxes { margin-bottom: 20px;  padding: 20px !important;}

.pkgtxt span {
    color: #1E1E1E;
    font-size: 18.67px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.373px;
}

.pkgtxt h4 {
    color: #0F3573;
    font-size: 28.005px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.56px;
}

.pkgtxt p {
    color: #000;
    text-align: justify;
    font-size: 18.67px;
    font-weight: 400;
    line-height: 161.523%;
}

.loct_jorny span {
    color: #000;
    font-size: 18.67px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.loct_jorny svg {
    position: relative;
    top: -2px;
}
.tourprc h3 {
    color:  #0B9138;
    font-size: 43.451px;
    font-style: normal;
}

.tourprc p {
    color: #000;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.tourprc span {
    color: #000;
    font-size: 17.38px;
    font-weight: 600;
    line-height: normal;
}

p.cutprice {
    color: #8C8C8C;
    font-size: 27.809px;
    font-weight: 500;
    line-height: normal;
    text-decoration: line-through;
}

.pkgbtns {
    margin-top: 15px;
}

.pkgbtns .btnswrp {
    display: flex;
    gap: 15px;
}

a.btn_know.bgbtn {
    background: #13446C;
    color: #fff !important;
}

.cab_dt_box.frtxt.flwdth {
    width: auto;
    margin-left: 20px;
}

ul.paymode {
    display: flex;
}

ul.paymode li {
    padding-right: 15px;
    margin-top: 10px;
}

ul.paymode label {
    color: #4D5358;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

input:focus~.checkmark {
    box-shadow: inset 2px 2px 4px #5c9abc, inset -2px -2px 4px #ffffff;
}

.loginmodl button.bookbtn.btn.btn-primary {
    background: #06446b;
    color: #fff;
    width: 100%;
    border-radius: 30px;
    height: 50px;
    box-shadow: -14px -14px 22.5px 2.5px rgb(255 255 255), 14px 14px 22.5px 2.5px rgb(92 154 188 / 47%);
    border: 0;
    margin: 15px 0;
}

.loginmodl input.form-control.box_sh_inner {
    box-shadow: inset -6px -8px 5px 0px rgb(255 255 255), inset 7px 7px 5px 0px rgb(92 154 188 / 50%) !important;
    margin: 10px 0;
    height: 50px;
    border-radius: 25px;
    position: relative;
    background-color: #e4eef4;
    color: #06446b;
    padding-left: 20px;
    font-weight: 500;
}

.loginmodl h2 {
    color:#13446C;
    font-size:35.143px;
    margin-bottom:22px;
    font-weight:700;
    line-height:normal;
}

.loginmodl label {color: #4D5358;font-size:18px;font-weight: 600;line-height: normal;}

.loginmodl .form-element {
    text-align: left;
}
a.resend {
    display: block;
    margin-top: 10px;
    font-weight: 600;
    color: #13b013;
}


a.resend span {
    color: #000;
}
.ottp input {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    margin: 0px 6px;
    border: navajowhite;
    box-shadow: 0px 0px 10px 0px #0000001f;
    border-radius: 10px;
  }
  .sidecolmfxd {
    background: #13446C;
    box-shadow: 6px 5px 6px 1px #B0CCDD, -4px -4px 7px 1px rgba(255, 255, 255, 0.77);
    height: 100vh; 
    width: 290px;
}


.sidecolmfxd .nav-link.active {
    background-color: #DCE7ED !important;
    box-shadow: -4px -5px 8px 3px rgba(255, 255, 255, 0.77) inset, 9px 8px 6px 0px #B0CCDD inset;
    color: #13446C !important;
}

.sidecolmfxd {
    padding-top: 20px;
    padding-left: 10px !important;
    padding-right: 10px;
}

.sidecolmfxd .nav-link {
    font-size: 17px;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    color: #DCE7ED;
    line-height: 19.77px; /* 85.959% */
    letter-spacing: 0.824px;
    display: block;
    margin-bottom: 15px;
}

.sidecolmfxd .nav-link:hover { 
    color: #DCE7ED; 
}

.act { display: none; }

.sidecolmfxd .nav-link.active .act { display: inline-block; }
.sidecolmfxd .nav-link.active .inact { display: none; }


.uploadFile {
    width: 115px;
    height: 115px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: grey;
    font-size: 16px;
    line-height: 23px;
    overflow: hidden;
    padding: 10px 10px 4px 10px;
    position: relative;
    resize: none;
    background-color:#DCE7ED;
    box-shadow: -4.65789px -5.82237px 9.31579px 3.49342px rgba(255, 255, 255, 0.77) inset, 10.48026px 9.31579px 6.98684px 0px #B0CCDD inset;

}
.uploadFile [type="file"] {
      cursor: pointer !important;
      display: block;
      font-size: 999px;
      filter: alpha(opacity=0);
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
      position: absolute;
      right: 0px;
      text-align: right;
      top: 0px;
      z-index: 1;
}

.proflimg h5 {
    font-size: 17.467px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.289px; /* 133.333% */
    margin-top: 10px;
}
.tbcntwrpr h3 {
    color: #1F384C;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px; /* 92% */
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}


.proflformwrp .form-control {
    border-radius: 11px;
    background: #DCE7ED;
    box-shadow: -4px -5px 8px 3px rgba(255, 255, 255, 0.77) inset, 9px 8px 6px 0px #B0CCDD inset !important;
    height: 46px;
    margin-bottom: 15px;
}

.proflformwrp label{
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    margin-bottom: 10px;
}

.proflform_row {
    display: flex;
}

.proflimg {
    flex: 0 0 20%;
}

.proflformwrp {
    flex: 0 0 70%;
}
.proflformwrp textarea {
    height: auto !important;
}

input.search_cb.prflbtn {
    width: 180px;
    float: right;
    position: relative;
    bottom: -40px;
    right: 5px;
}
input.search_cb.prflbtn:hover {
    background: #000;
    color: #fff;
}

.d-flxbtwn{ display: flex; align-items: center; justify-content: space-between;}
.bookboxdash p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 16px; /* 88.889% */
    letter-spacing: 0.32px;
}

.bookboxdash {
    border-radius: 22px;
    background: #DCE7ED;
    box-shadow: 6px 5px 6px 1px #B0CCDD, -4px -4px 7px 1px rgba(255, 255, 255, 0.77);
    padding: 15px;
    margin-bottom: 20px;
}

span.bggrn {
    border-radius: 5px;
    background: var(--green, #4BA487);
    color: #fff;
    display: inline-block;
    padding: 7px 12px;
}
.bgblue{
    border-radius: 5px;
    background: #13446C;
    color: #fff;
    display: inline-block;
    padding: 7px 12px;  
}
.d-flxbtwn.addrstag {
    margin: 15px 0;
}

.bkndtls {
    display: flex;
    justify-content: space-between;
    flex: 0 0 50%;
}

.abtbkng span {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    display: block;
    margin-bottom: 8px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
}
.bknbtnswrp {
    display: flex;
    gap: 10px;
}

a.bkbtns {
    border-radius: 5px;
    background: var(--light, #DCE7ED);
    box-shadow: 6px 5px 6px 1px #B0CCDD, -4px -4px 7px 1px rgba(255, 255, 255, 0.77);
    padding: 5px 14px;
    color: #13446C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

a.bkbtns.actv {
    background: #13446C;
    color: #fff;
}



.wltbox {
    border-radius: 22px;
    background: var(--light, #DCE7ED);
    box-shadow: -4px -5px 8px 3px rgba(255, 255, 255, 0.77) inset, 9px 8px 6px 0px #B0CCDD inset;
    padding: 35px 25px;
    margin-bottom: 20px;
}

.wltbox h2 {
    color: var(--blue, #13446C);
    font-size: 34.13px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.683px;
}

.wltbox a {
    color: var(--blue, #13446C);
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
}

.addamnt {
    display: flex;
    align-items: center;
}

.addamnt h5 {
    color: var(--blue, #13446C);
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    padding-right: 20px;
}

.addamnt input {
    border-radius: 28px;
    background: var(--light, #DCE7ED);

/* inner */
    box-shadow: -4px -5px 8px 3px rgba(255, 255, 255, 0.77) inset, 9px 8px 6px 0px #B0CCDD inset;
    width: 250px;
    height: 46px;
    padding: 0 10px;
    border: 0;
}

.selectcamnt {
    display: flex;
    margin-top: 25px;
}

.selectcamnt h6 {
    color: var(--blue, #13446C);
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
}

.amntbtns {
    display: flex;
    gap: 12px;
    margin-left: 15px;
}

button.amntbtn {
    border-radius: 5px;
    background: #DCE7ED;
    box-shadow: 6px 5px 6px 1px #B0CCDD, -4px -4px 7px 1px rgba(255, 255, 255, 0.77);
    border: 0;
    color: #13446C;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    font-weight: 500;
}

button.amntbtn:hover {
   background-color: #13446C;
   color:#fff;
}

button.paybtn {
    border-radius: 38px;
    background: var(--blue, #13446C);
    box-shadow: 6px 5px 6px 1px #B0CCDD, -4px -4px 7px 1px rgba(255, 255, 255, 0.77);
    color: #fff;
    width: 220px;
    margin-top: 30px;
    padding: 8px;
    border: 0;
}


.bkngdtls.bookboxdash {
    border-radius: 32px;
    background: var(--light, #DCE7ED);
    box-shadow: -4px -5px 8px 3px rgba(255, 255, 255, 0.77) inset, 9px 8px 6px 0px #B0CCDD inset;
    padding: 20px;
}

.dashroute {
    background: var(--blue, #13446C);
    padding: 20px 15px;
    margin-bottom: 15px;
}

.dashroute p {
    margin: 0;
    color: #fff;
}

p.bkid {
    margin-top: 15px;
    margin-bottom: 20px;
    color: #1F384C;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px; /* 104.545% */
    letter-spacing: 0.5px;
}

.dtlsfields {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #0F1417;
    margin-bottom: 15px;
}

.dfld {
    flex: 0 0 33%;
}

.dfld p {
    font-weight: 400;
    font-size: 15px;
}

.dfld p span {
    font-weight: 500;
    margin-right: 5px;
}

.dtlsfields:last-child{ border: 0;}
.bkngdtls .bkiddate p {
    margin: 0;
}

button.dwnldbtn {
    border-radius: 10px;
    background:  #4BA487;
    border: 0;
    color: #fff;
    padding: 5px 12px;
}
button.dwnldbtn {
    border-radius: 10px;
    background: #4BA487;
    border: 0;
    color: #fff;
    padding: 5px 12px;
}

.backbtnid button {
    border: 0;
    border-radius: 8px;
    background: var(--blue, #13446C);
    box-shadow: 6px 5px 6px 1px #B0CCDD, -4px -4px 7px 1px rgba(255, 255, 255, 0.77);
    padding: 0;
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.backbtnid {
    margin-bottom: 12px;
}

.backbtnid span {
    color: #1F384C;
    font-size: 22px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.5px;
}

.pointer{
    cursor: pointer;
}


.daternge {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.daternge button.search_cb.prflbtn {
    width: 140px;
}

.daternge p {
    margin: 0;
    color: #13446C;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sltdate label {
    color: #13446C;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 15px;
}


.daternge {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.daternge button.search_cb.prflbtn {
    width: 140px;
}

.daternge p {
    margin: 0;
    color: #13446C;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sltdate label {
    color: #13446C;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 15px;
}

.sltdate input {
    border-radius: 10px;
    background: #DCE7ED;
    box-shadow: 6px 5px 6px 1px #B0CCDD, -4px -4px 7px 1px rgba(255, 255, 255, 0.77);
    height:50px;
}

.sltdate {
    position: relative;
}

.sltdate svg {
    position:absolute;
    right:135px;
    top:12px;
    color:#06446b;
}

.transactwrpr { margin-top:20px; }


.wallttable {
    border-radius: 22px;
    background:#DCE7ED;
    box-shadow: -4px -5px 8px 3px rgba(255, 255, 255, 0.77) inset, 9px 8px 6px 0px #B0CCDD inset;
    padding: 20px;
}

.wallttable table {
    width: 100%;
}

.wallttable table th {
    background: #13446C;
    color: #fff;
    text-align: center;
    padding: 10px;
}

.wallttable table td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #0F1417;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
}

.wallttable table tr:last-child td {
    border: 0;
}
td.txtgrn {
    color: #4BA487;
}

td.txtred {
    color: #A2191F;
}
.walltwrpr h3 {
    color: #1F384C;
    font-size: 25px;
    font-weight: 700;
    line-height: 23px; /* 92% */
    letter-spacing: 0.5px;
}